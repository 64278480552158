export const FaqView = Backbone.View.extend({
  minCharSearch: 2,
  /**
   * Initialise component
   * @requires DEVICE
   * @requires Tracking
   * @requires genericScrollTo
   */
  initialize: function () {
    const $faqQuestionBlock = $('.faq-question');
    const $searchInput = $('.js-faq-searchInput');

    if ($('.faq-Banner').length) {
      $('body').addClass('faq-page');
    }

    $(window).on('load', (e) => {
      this.displayPage(e);

      if (!$('.faq-container').hasClass('faq-container--Home')) {
        this.addStructuredDatas();
      }

      $('.js-faq-linkToCategory').on('click', (event) => {
        event.preventDefault();
        const $target = $(event.target);
        const $item = $target.hasClass('faq-allCategoriesLink') ? $target : $target.closest('.faq-allCategoriesLink');
        const $allCategoriesList = $('.faq-allCategoriesList');
        const isSearchResult = window.location.href.split('search')[1] !== undefined;
        const url = $item.attr('href');

        if (!$target.parents('.faq-allCategoriesList').hasClass('faq-allCategoriesList--withQuestion')) {
          Tracking.dataLayerPush({
            clickCode: 'clickQuestionFaq',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: $item.data('label'),
            gaLabel: 'click',
            event: 'clickEvent'
          });
        } else {
          Tracking.dataLayerPush({
            userConsent: false,
            environment: GLOBAL_SCRIPTS.ENV,
            currency: GLOBAL_SCRIPTS.CURRENCY_CODE,
            language: GLOBAL_SCRIPTS.LANGUAGE_CODE,
            siteCountry: GLOBAL_SCRIPTS.MARKET_CODE,
            pageCategoryLevel1: isSearchResult ? 'FAQ Search' : 'FAQ Category',
            pageTemplate: PAGE_SCRIPTS.page_template,
            pageName: '/FAQ/' + $item.data('label'),
            page_shrturl: GLOBAL_SCRIPTS.MARKET_LANGUAGE + '/FAQ/' + $item.data('label'),
            event: 'clickEvent',
            clickCode: 'clickFaqPageview'
          });
        }

        $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
        $('.faq-searchQuestionsAnswers .faq-question, .button--suggestions').remove();
        $('.faqText').removeClass('hidden');
        $('.faq-container').removeClass('faq-container--Home');

        if ($item.hasClass('active') && !DEVICE.isDesktop) {
          $target.parents('.faq-allCategoriesLink').removeClass('active');
          $target.removeClass('active');
        } else {
          if ($item.next('.faqText').length === 0) {
            this.getCategory(event);
          } else {
            window.history.pushState({path: url}, '', url);

            $('.faq-allCategoriesLink').removeClass('active');
            $('.faq-topQuestions').addClass('hidden');
            $allCategoriesList.addClass('faq-allCategoriesList--withQuestion');
            $item.addClass('active');
          }
        }

        if ($allCategoriesList.length && $allCategoriesList.hasClass('faq-allCategoriesList--withQuestion')) {
          if ((DEVICE.isMobile || DEVICE.isTablet) && $('.faq-allCategoriesLink.active').length) {
            genericScrollTo.go($('.faq-allCategoriesLink.active').offset().top);
          }
        }

        this.faqUpdateHeight();
      });
    });

    $(window).on('popstate', (e) => {
      this.displayPage(e);
    });

    $('.faq-question--title').on('click', (event) => {
      const $this = $(event.target);
      const $parent = $this.closest($faqQuestionBlock);
      const url = GLOBAL_SCRIPTS.BASELINK + '/faq/' + $parent.data('url');
      const item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
      const index = $('.faq-question').index(item) + 1;

      window.history.pushState({path: url}, '', url);

      $parent.toggleClass('active');

      if ($this.hasClass('faq-questionSearch')) {
        Tracking.dataLayerPush({
          clickCode: 'FaqSuggestionClick',
          gaCategory: PAGE_SCRIPTS.page_template,
          gaAction: 'FAQ Question Click',
          gaLabel: 'pos' + index + ' - ' + $this.text(),
          gaValue: index,
          freesearch: striptags($('.faq-searchInput').val()),
          event: 'clickEvent'
        });
      }

      if (DEVICE.isDesktop) {
        this.faqUpdateHeight();
      }
    });

    //handling specific active block on desktop
    if (DEVICE.isDesktop) {
      this.faqUpdateHeight();
    }

    /* SEARCH ENGINE EVENT */
    $('.js-faq-searchSubmit').on('click', (event) => {
      this.searchSubmit(event);
    });

    $searchInput.on('focus', () => {
      this.searchFocus();
    });

    $searchInput.on('keyup', (event) => {
      this.searchKeyup(event);
    });

    $searchInput.on('blur', () => {
      $('.faq-searchContainer').remove('searchFaqOpened');
    });

    $('body .faq-content, body .faq-BannerImage, .header, .h2-like').on('click', () => {
      this.closeAutocomplete();
    });

    this.toggleTopQuestion();

    $('.js-back-home').off('click').on('click', (event) => {
      this.backHome(event);
      this.getCategory(event);
      this.toggleTopQuestion();
    });
  },

  /**
   * Search action on submit
   * @param {Event} event
   * @memberOf module:FaqSearch
   * @requires Tracking
   */
  searchSubmit(event) {
    event.preventDefault();
    if ($('.faq-searchInput').val().length > this.minCharSearch) {
      this.getQuestionsSearch(event);

      Tracking.dataLayerPush({
        clickCode: 'clickFaqSearch',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'FAQ Search',
        gaLabel: 'rechercher',
        freesearch: striptags($('.faq-searchInput').val()),
        event: 'clickEvent'
      });
    } else {
      $('.js-faq-searchSubmit').removeClass('buttonLoader');
    }
  },

  /**
   * Search action on keyup
   * @param {Event} event
   * @memberOf module:FaqSearch
   */
  searchKeyup(event) {
    const $searchClose = $('.faq-searchInputReset');
    const nbCharacter = event.target.value.length;
    const $searchQuestion = $('.faq-searchQuestion');

    if (!$searchClose.length && nbCharacter > 0) {
      this.addResetSearchValue();
    }

    if (nbCharacter > this.minCharSearch && (event.key === 'Enter' || event.keyCode === 13)) {
      this.getQuestionsSearch(event);
      $('.js-faq-searchInput').blur();
      this.closeAutocomplete();
      $('.faq-allCategoriesLink').removeClass('active');
      $searchQuestion.remove();
    } else {
      $('.js-faq-searchSubmit').addClass('disabled');

      if (nbCharacter > this.minCharSearch) {
        $('.js-faq-searchSubmit').removeClass('disabled');
        $('.faq-searchQuestion--first').remove();
        this.getQuestionsOnAutocomplete();
      } else if (nbCharacter === 0) {
        this.closeAutocomplete();
      } else {
        $searchQuestion.remove();
        if ($('.faq-searchQuestion--first').length === 0) {
          $('.faq-searchContainer').append('<div class="faq-searchQuestion faq-searchQuestion--first">' + FAQ_SCRIPTS.WORDINGS_FAQ.fill_3_car + '</div>');
        }
      }
    }
  },

  /**
   * Focus search question
   * @memberOf module:FaqSearch
   */
  searchFocus() {
    const $searchContainer = $('.faq-searchContainer');
    const $searchQuestionFirst = $('.faq-searchQuestion--first');
    const nbCharacter = $('.js-faq-searchInput').val().length;

    $searchContainer.addClass('searchFaqOpened');

    if ($searchQuestionFirst.length === 0) {
      $searchContainer.append('<div class="faq-searchQuestion faq-searchQuestion--first">' + FAQ_SCRIPTS.WORDINGS_FAQ.fill_3_car + '</div>');
    }

    if (nbCharacter > this.minCharSearch) {
      $searchQuestionFirst.remove();
      $('.js-faq-searchSubmit').removeClass('disabled');
      this.getQuestionsOnAutocomplete();
    } else {
      $('.faq-searchQuestion').remove();
    }

    if (!$('.faq-searchInputReset').length && nbCharacter > 0) {
      this.addResetSearchValue();
    }
  },

  /**
   * Get category
   * @param {Event} event
   * @requires DEVICE
   * @requires genericScrollTo
   * @requires genericAjaxCall
   */
  getCategory(event) {
    event.preventDefault();
    const $this = $(event.currentTarget).hasClass('faq-allCategoriesItem') ? $(event.currentTarget) : $(event.currentTarget).find('.faq-allCategoriesItem');
    const url = $this.attr('href') || $this.parent().attr('href') || GLOBAL_SCRIPTS.BASELINK + '/faq/';
    const idCategory = $this.data('id') || $this.parents('.faq-allCategoriesLink').data('id') || '';
    const categoryLabel = $this.text();
    const $linkCategory = $this.parent('.faq-allCategoriesLink');
    const ajaxUrl = idCategory !== '' ? `${FAQ_SCRIPTS.URL_API_FAQ}/v1/category/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}/${idCategory}` : `${FAQ_SCRIPTS.URL_API_FAQ}/v1/categories/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}`;

    const successCallback = (response) => {
      if (response.status === 'success') {
        const questions = response.data.questions;

        if (event.type === 'click' && idCategory !== '') {
          $('.faq-allCategoriesLink, .faqText').removeClass('active');
          $('.faq-topQuestions, .faq-allCategoriesTitle').addClass('hidden');
          $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
          $('.faq-allCategoriesLink[data-id=' + idCategory + ']').after('<div class="faqText active"></div>');
          $('.faq-allCategoriesLink[data-id=' + idCategory + '] + .faqText').append('<h1 class="faqTitle">' + categoryLabel + '</h1><p class="faq-lastUpdate">' + FAQ_SCRIPTS.WORDINGS_FAQ.faq_last_update + '</p>');
          $linkCategory.addClass('active');

          window.history.pushState({path: url}, '', url);

          $.each(questions, (i, item) => {
            const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
            const idCategory = item.category.id;
            const additionalClass = item.selected ? 'active' : '';

            $('.faq-allCategoriesLink.active + .faqText').append(this.questionTemplate(item, additionalClass));

            if (idCategory !== '') {
              $('.faq-question' + item.id + '[data-idcategory=' + idCategory + '] .js-faq-question').on('click', (e) => {
                $(e.currentTarget).closest('.faq-question').toggleClass('active');
                this.faqUpdateHeight();
                window.history.pushState({path: urlQuestion}, '', urlQuestion);
              });
            }
          });

          const scrollTarget = DEVICE.isMobile || DEVICE.isTablet ? '.faq-allCategoriesLink.active' : '.faq-allCategoriesList';
          const scrollOffset = DEVICE.isMobile || DEVICE.isTablet ? parseInt($('.faq-information').css('margin-bottom')) / 2 : 0;
          genericScrollTo.go(scrollTarget, 500, scrollOffset);

        } else if (!$('.faq-searchTopQuestion').length) {
          $.each(questions, (i, item) => {
            if (i < 5) {
              const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
              const labelQuestion = item.label;
              const idQuestion = item.id;
              const idCategory = item.category.id;
              $('.faq-topQuestions-list').append(`
                <div class="faq-searchTopQuestion">
                  <a href="${urlQuestion}" class="js-faq-topQuestion faq-topQuestions-link faq-topQuestions-item" data-idquestion="${idQuestion}" data-idcategory="${idCategory}">
                    ${labelQuestion}
                  </a>
                </div>`);
            }
          });
          $('.faq-topQuestions-title').html(FAQ_SCRIPTS.WORDINGS_FAQ.faq_top_questions);
          $('.faq-topQuestions').removeClass('hidden');
          $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
        }
        this.faqUpdateHeight();
      }
    };
    genericAjaxCall({
      ajaxUrl: ajaxUrl,
      successCallback,
      dataType: 'json',
      type: 'GET'
    });
  },

  /**
   * Home top question list
   * @requires DEVICE
   * @requires Tracking
   * @requires genericScrollTo
   * @requires genericAjaxCall
   */
  toggleTopQuestion() {
    $('.js-toggleQuestions').off('click').on('click', () => {
      $('.faq-topQuestions-title, .faq-topQuestions').toggleClass('open');

      Tracking.dataLayerPush({
        clickCode: 'clickQuestionFaq',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'Les tops questions du moment',
        gaLabel: 'click',
        event: 'clickEvent'
      });
    });

    $('.js-faq-topQuestion').on('click', (event) => {
      event.preventDefault();
      const $item = $(event.target);
      const idCategory = $item.data('idcategory');
      const idQuestionSelected = $item.data('idquestion');
      const $category = $('.faq-allCategoriesLink[data-id=' + idCategory + ']');
      const url = $item.attr('href');

      $('.faq-container').removeClass('faq-container--Home');
      $category.addClass('active');

      const successCallback = (response) => {
        window.history.pushState({path: url}, '', url);

        $('.faq-topQuestions-title, .faq-topQuestions').removeClass('open');
        $('.faq-topQuestions').css('max-height', '').addClass('hidden');
        $('.faq-allCategoriesTitle, .faqTitleSearch, .faq-searchSubtitle').addClass('hidden');

        if (response.status === 'success') {
          const questions = response.data.questions;

          $('.faq-allCategoriesLink').removeClass('active');
          $category.after('<div class="faqText active" />').addClass('active');
          $category.next('.faqText').append('<span class="faqTitle">' + $category.find('.faq-allCategoriesItem').html() + '</span>');

          $(questions).each((_i, item) => {
            const additionalClass = idQuestionSelected === parseInt(item.id) ? 'active' : '';

            $category.next('.faqText').append(this.questionTemplate(item, additionalClass));
            $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
            $('.faq-question' + item.id).on('click', ({currentTarget}) => {
              const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;

              window.history.pushState({path: urlQuestion}, '', urlQuestion);
              $(currentTarget).toggleClass('active');
              this.faqUpdateHeight();
            });
          });

          genericScrollTo.go($('.faq-question.active').offset().top - 20);
        }
        this.faqUpdateHeight();
      };

      genericAjaxCall({
        ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/category/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}/${idCategory}`,
        successCallback,
        dataType: 'json',
        type: 'GET'
      });
    });
  },

  /** @module FaqSearch **/
  /**
   * Get one question for Search form
   * @param {Event} event
   * @memberOf module:FaqSearch
   * @requires DEVICE
   */
  getOneQuestion(event) {
    event.preventDefault();
    const $this = $(event.target);
    const $item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
    const question = $item.html();
    const url = $item.attr('href') || $item.data('url');
    const idQuestion = $item.data('id');
    const $searchAnswer = $item.siblings('.faq-searchAnswerContainer');
    const answers = $searchAnswer[0] ? $searchAnswer[0].outerHTML : '';
    const containerQuestionAnswer = `
      <div class="faq-question faq-question${idQuestion} active">
        <div class="js-faq-question faq-question--block">
          <h2 class="faq-question--title">${question}</h2>
        </div>
        <div class="faq-answer">${this.wrapAnswerLabel(answers)}</div>
      </div>`;

    $('.faq-searchQuestionsAnswers .faq-question').remove();
    $('.faq-searchSubtitle').after(containerQuestionAnswer);
    $('.faq-allCategoriesLink, .faqText').removeClass('active');

    if (!DEVICE.isDesktop) {
      $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faq-allCategoriesList').addClass('hidden');
    }

    $('.faqTitleSearch').removeClass('hidden').html(FAQ_SCRIPTS.WORDINGS_FAQ.faq_search_title);

    $('.faq-searchQuestionsAnswers .js-faq-question').on('click', () => {
      $('.faq-question').toggleClass('active');
      this.faqUpdateHeight();
      window.history.pushState({path: url}, '', url);
    });

    $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faqText, .faq-searchSubtitle, .faq-searchSubtitle + .button').addClass('hidden');
    $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
    $('.faq-searchQuestionsAnswers').addClass('faq-searchQuestionsAnswers--open');
    $('.js-faq-searchSubmit').removeClass('buttonLoader');
    $('.faq-searchQuestionSuggestion').remove();
  },

  /**
   * Do and Show the search results
   * @param {Event} event
   * @memberOf module:FaqSearch
   * @requires DEVICES
   * @requires Tracking
   * @requires striptags
   * @requires genericAjaxCall
   */
  getQuestionsSearch(event) {
    event.preventDefault();
    const value = striptags($('.js-faq-searchInput').val());
    const url = GLOBAL_SCRIPTS.BASELINK + '/faq/search?query=' + value;

    $('.faq-searchQuestion').remove();
    $('.faq-topQuestions--container, .faq-allCategoriesTitle').addClass('hidden');
    $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');
    $('.faq-allCategoriesLink, .faqText').removeClass('active');
    $('.faqTitleSearch, .faq-searchSubtitle').removeClass('hidden');

    const successCallback = (response) => {
      if (response.status === 'success') {
        const $faqTitleSearch = $('.faqTitleSearch');
        const $searchSubtitle = $('.faq-searchSubtitle');
        const data = response.data;
        const questions = data.questions;
        const {results_singular, results_plural, no_results, see_mostly_asked} = FAQ_SCRIPTS.WORDINGS_FAQ;

        $faqTitleSearch.text(value);

        if (questions.length > 0) {
          $('.button--suggestions').remove();
          $searchSubtitle.html(questions.length === 1 ? questions.length + ' ' + results_singular : questions.length + ' ' + results_plural);
        } else {
          $searchSubtitle.html(no_results);

          if ($('.button--suggestions').length === 0) {
            $searchSubtitle.after(`<span class="button button--greenWhite button--suggestions js-back-home">${see_mostly_asked}</span>`);
            $('.js-back-home').off('click').on('click', (ev) => {
              this.backHome(ev);
              this.getCategory(ev);
              this.toggleTopQuestion();
            });
          }
        }

        $('.faq-searchQuestionsAnswers .faq-question').remove();

        if (questions.length > 0) {
          $.each(questions, (i, {question}) => {
            $('.faq-searchSubtitle').before(this.questionTemplate(question, 'faq-questionSearch'));

            $('.faq-searchQuestionsAnswers .faq-question' + question.id).on('click', ({currentTarget}) => {
              $(currentTarget).toggleClass('active');

              if ($(currentTarget).hasClass('faq-questionSearch')) {
                Tracking.dataLayerPush({
                  clickCode: 'FaqSuggestionClick',
                  gaCategory: PAGE_SCRIPTS.page_template,
                  gaAction: 'FAQ Question Click',
                  gaLabel: 'pos' + (i + 1) + ' - ' + question.label,
                  gaValue: i + 1,
                  freesearch: striptags($('.faq-searchInput').val()),
                  event: 'clickEvent'
                });
              }
            });
          });

          $('.faqTitleSearch').after($('.faq-searchSubtitle'));
          $('.faq-question + .faq-searchSubtitle').remove();
        }

        if (DEVICE.isMobile || DEVICE.isTablet) {
          $('.faq-allCategoriesList').addClass('hidden');
        }

        $('.faq-searchQuestionsAnswers').addClass('faq-searchQuestionsAnswers--open');
        $('.js-faq-searchSubmit').removeClass('buttonLoader');
        $('.faq-searchQuestionSuggestion').remove();

        Tracking.dataLayerPush({
          event: 'search_results_displayed',
          sub_event: 'FAQ Search',
          gaCategory: PAGE_SCRIPTS.page_template,
          gaLabel: value,
          list_results_number: questions.length
        });
      }

      this.faqUpdateHeight();
    };

    genericAjaxCall({
      ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/search/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}?query=${encodeURIComponent(value)}`,
      successCallback,
      dataType: 'json',
      type: 'GET'
    });
  },

  /**
   * Close autocomplete results list
   * @memberOf module:FaqSearch
   */
  closeAutocomplete() {
    $('.faq-searchContainer').removeClass('searchFaqOpened');
    $('.faq-searchInputReset, .faq-searchQuestion, .faq-searchContainer .faq-searchQuestion').remove();
    $('.js-faq-searchInput').blur();
  },

  /**
   * Reset search field and results
   * @memberOf module:FaqSearch
   * @requires Tracking
   */
  addResetSearchValue() {
    $('.faq-searchContainer').prepend('<div class="faq-searchInputReset"></div>');

    $('.faq-searchInputReset').css('right', $('.js-faq-searchSubmit').width() + 20).on('click', () => {
      $('.faq-searchInput').val('');
      this.closeAutocomplete();
      $('.js-faq-searchInput').focus();
      $('.faq-searchContainer').remove('searchFaqOpened');
      $('.js-faq-searchSubmit').addClass('disabled');

      Tracking.dataLayerPush({
        clickCode: 'clickFaqSuggestion',
        gaCategory: PAGE_SCRIPTS.page_template,
        gaAction: 'FAQ Search',
        gaLabel: 'Reset search',
        event: 'clickEvent'
      });
    });
  },

  /**
   * Get questions and show autocomplete list
   * @memberOf module:FaqSearch
   * @requires Tracking
   * @requires striptags
   */
  getQuestionsOnAutocomplete() {
    const $searchContainer = $('.faq-searchContainer');
    const $searchInput = $('.js-faq-searchInput');
    const value = striptags($searchInput.val());

    const successCallback = (response) => {
      if (response.status === 'success') {
        const data = response.data;
        const questions = data.questions;

        $('.faq-searchQuestion').remove();
        $('.faq-searchQuestionSuggestion').remove();

        $.each(questions, (i, item) => {
          const urlQuestion = GLOBAL_SCRIPTS.BASELINK + '/faq/' + item.slug;
          const label = item.label;
          const buttonUrl = item.url || '';
          const buttonTitle = item.urlTitle || '';
          const buttonLabel = item.urlText || '';

          let divQuestions = `
            <div class="faq-searchQuestion">
              <a href="${urlQuestion}" data-id="${item.id}" class="faq-searchQuestionLink js-faq-searchQuestion">${label}</a>
              <div class="faq-searchAnswerContainer">
                <div class="faq-searchAnswer">${this.wrapAnswerLabel(item.answer.label)}`;

          if (buttonUrl !== '') {
            divQuestions += `<a href="${buttonUrl}" class="faq-searchButton button button--transparentBlack" title="${buttonTitle}">${buttonLabel}</a>`;
          }

          divQuestions += '</div></div></div>';
          $searchContainer.append(divQuestions);

          Tracking.dataLayerPush({
            clickCode: 'FaqSuggestionImpression',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'FAQ-Suggestion Impression',
            gaLabel: 'pos' + (i + 1) + ' - ' + item.label,
            gaValue: i + 1,
            freesearchQuery: striptags($('.faq-searchInput').val()),
            event: 'clickEvent'
          });
        });

        if (questions.length > 0) {
          if (data.viewMore) {
            const divSuggestions = '<div class="faq-searchQuestion faq-searchQuestionSuggestion js-faq-searchQuestionSuggestion"><a href="#">' + FAQ_SCRIPTS.WORDINGS_FAQ.see_suggestions + '</a></div>';
            $searchContainer.append(divSuggestions);
          }

          $('.js-faq-searchQuestionSuggestion').on('click', (event) => {
            event.preventDefault();
            this.getQuestionsSearch(event);

            Tracking.dataLayerPush({
              clickCode: 'clickFaqSuggestion',
              gaCategory: PAGE_SCRIPTS.page_template,
              gaAction: 'FAQ Search',
              gaLabel: 'Voir toutes les suggestions',
              freesearchQuery: striptags($('.faq-searchInput').val()),
              event: 'clickEvent'
            });
          });
        } else if (questions.length === 0) {
          $searchContainer.append('<div class="js-faq-searchSubmit faq-searchQuestion faq-searchQuestionNoAsnwser">' + FAQ_SCRIPTS.WORDINGS_FAQ.no_results + '</div>');
        }

        $('.faq-searchInputReset').on('click', () => {
          $('.faq-searchQuestion:not(.faq-searchQuestion--first)').remove();
        });

        $('.js-faq-searchQuestion').on('click', (event) => {
          event.preventDefault();
          const $this = $(event.target);
          const item = $this.hasClass('faq-searchQuestionLink') ? $this : $this.parents('.faq-searchQuestionLink');
          const index = $('.faq-searchQuestionLink').index(item) + 1;

          Tracking.dataLayerPush({
            clickCode: 'FaqSuggestionClick',
            gaCategory: PAGE_SCRIPTS.page_template,
            gaAction: 'FAQ Suggestion Click',
            gaLabel: 'pos' + index + ' - ' + item.text(),
            gaValue: index,
            freesearchQuery: striptags($('.faq-searchInput').val()),
            event: 'clickEvent'
          });
          this.closeAutocomplete();
          this.getOneQuestion(event);
        });
      }
    };

    genericAjaxCall({
      ajaxUrl: `${FAQ_SCRIPTS.URL_API_FAQ}/v1/autocomplete/${GLOBAL_SCRIPTS.UNIVERS}/${GLOBAL_SCRIPTS.LANGUAGE_CODE}?query=${encodeURIComponent(value)}`,
      successCallback,
      dataType: 'json',
      type: 'GET'
    });
  },

  /**
   * Back to FAQ home action
   * @param {Event} event
   * @requires Tracking
   * @requires genericScrollTo
   */
  backHome(event) {
    const $this = $(event.target);
    const isNormalBack = $this.hasClass('faqTitleBackHome');
    const url = GLOBAL_SCRIPTS.BASELINK + '/faq';

    window.history.pushState({path: url}, '', url);

    $('.faq-container').addClass('faq-container--Home');
    $('.faq-topQuestions--container, .faq-topQuestions, .faq-allCategoriesTitle, .faq-allCategoriesList').removeClass('hidden');
    $('.faq-allCategoriesLink, .faqText').removeClass('active');
    $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
    $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
    genericScrollTo.go(0);

    Tracking.dataLayerPush({
      clickCode: isNormalBack ? 'clickFaqRetour' : 'clickFaqSeeQuestionsCTA',
      gaCategory: PAGE_SCRIPTS.page_template,
      gaAction: 'FAQ Search',
      gaLabel: isNormalBack ? 'Retour' : 'Voir les questions fréquentes',
      event: 'clickEvent',
      freesearch: isNormalBack ? '' : $('.faqTitleSearch').text()
    });
  },

  /**
   * Add structured JSON datas to head
   */
  addStructuredDatas() {
    const structuredDataText = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      'mainEntity': []
    };

    $('.faq-question').each((_i, item) => {
      const $item = $(item);

      structuredDataText.mainEntity.push({
        '@type': 'Question',
        'name': $item.find('.faq-question--title').text(),
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': $item.find('.faq-answer').text().replace(/\n/g, '').replace(/"/g, '').trim()
        }
      });
    });

    $('head').append('<script type="application/ld+json">' + JSON.stringify(structuredDataText) + '</script>');
  },

  /**
   * Display page on Popstate event
   * @param {Event} event
   * @requires DEVICE
   * @requires genericScrollTo
   * @requires striptags
   */
  displayPage(event) {
    const url = window.location.href;
    const isSearchResult = url.split('search')[1] !== undefined;
    const isHome = url.substring(url.length - 3) === 'faq' || url.substring(url.length - 4) === 'faq/';
    const anchor = url.split('#')[1];
    const categoryId = url.substring(url.indexOf('faq/') + 4, url.lastIndexOf('_'));
    const category = url.split('faq/')[1] !== undefined ? url.substring(url.indexOf('faq/') + 4, anchor === undefined ? url.length : url.lastIndexOf('#')) : '';
    const $faqQuestionBlock = $('.faq-question');

    // CASE HOME
    if (isHome) {
      this.getCategory(event);
      $('.faq-container').addClass('faq-container--Home');
      $('.faq-allCategoriesList').removeClass('faq-allCategoriesList--withQuestion');
      $('.faq-topQuestions--container, .faq-topQuestions').removeClass('hidden');
      $('.faq-searchQuestionsAnswers').removeClass('faq-searchQuestionsAnswers--open');
      $('.faq-allCategoriesLink, .faqText').removeClass('active');
      $('.faq-searchQuestionsAnswers .faq-question, .button--suggestions').remove();
      genericScrollTo.go(0);
    }

    // CASE SEARCH
    if (isSearchResult) {
      const keyword = striptags(url.split('?query=')[1]);

      if (!DEVICE.isDesktop) {
        $('.faq-topQuestions--container, .faq-allCategoriesTitle, .faq-allCategoriesList').addClass('hidden');
      }

      $('.faq-searchInput').val(keyword);
      $('.faqTitleSearch').text(keyword);
      this.getQuestionsSearch(event);
    }

    // CASE ANCHOR URL
    else if (!isSearchResult && typeof anchor !== 'undefined') {
      $('.faq-allCategoriesLink, .faqText').removeClass('active');

      if (categoryId !== '') {
        $('.faq-allCategoriesLink[data-id=' + categoryId + ']').addClass('active');
      }

      $.map($faqQuestionBlock, (item) => {
        const $item = $(item);
        const faqQuestionTitle = $item.find('.faq-question--title');

        if ($(faqQuestionTitle).data('anchor') === anchor) {
          $item.addClass('active');
          genericScrollTo.go($item.offset().top, 500, DEVICE.isTablet ? -10 : -parseInt($item.find('.faq-question--title').css('padding-top')));
        } else {
          $item.closest($faqQuestionBlock).removeClass('active');
        }
      });

      this.faqUpdateHeight();
    }

    // CASE CATEGORY
    else if (!isSearchResult && category !== '') {
      $('.faq-allCategoriesLink, .faqText').removeClass('active');
      $('.faq-allCategoriesLink[data-id=' + categoryId + ']').addClass('active');
      $('.faq-allCategoriesList').addClass('faq-allCategoriesList--withQuestion');

      const scrollTarget = DEVICE.isMobile || DEVICE.isTablet ? '.faq-allCategoriesLink.active' : '.faq-allCategoriesList';
      const scrollOffset = DEVICE.isMobile || DEVICE.isTablet ? parseInt($('.faq-information').css('margin-bottom')) / 2 : 0;
      genericScrollTo.go(scrollTarget, 500, scrollOffset);

      this.faqUpdateHeight();
    }
  },

  /**
   * Update page container height on desktop
   */
  faqUpdateHeight() {
    const divContent = $('.faq-allCategoriesLink.active').length ? $('.faq-allCategoriesLink.active + .faqText').height() : $('.faq-searchQuestionsAnswers').height();
    const divMenu = $('.faq-allCategoriesList').height();
    const faqHeightContainer = divContent > divMenu ? divContent : divMenu;

    $('.faq-container').css('min-height', faqHeightContainer);
  },

  /**
   * Question html template
   * @param {Object} question
   * @param {string} classname
   * @returns {string}
   */
  questionTemplate(question, classname) {
    const {
      id,
      label,
      slug,
      anchor,
      category,
      answer,
      url = '',
      urlTitle = '',
      urlText = ''
    } = question;

    let container = `
<div class="faq-question faq-question${id} ${classname}" data-url="${slug}" data-idcategory="${category.id}">
  <div class="js-faq-question faq-question--block">
    <h2 class="faq-question--title" data-anchor="${anchor}" data-idquestion="${id}" data-idcategory="${category.id}">${label}</h2>
  </div>
  <div class="faq-answer">${this.wrapAnswerLabel(answer.label)}`;

    if (url !== '') {
      container += `<a href="${url}" class="faq-searchButton button button--transparentBlack" title="${urlTitle}">${urlText}</a>`;
    }

    container += '</div></div>';

    return container;
  },

  /**
   * Add html wrap to label content
   * @param {string} content
   */
  wrapAnswerLabel(content) {
    if (typeof content !== 'undefined' && !['<div', '<p>'].includes(content.slice(0, 3))) {
      return `<p>${content}</p>`;
    } else {
      return content;
    }
  }
});

$(() => {
  const faqView = new FaqView();
});